import React from 'react';

import Schedule from './Schedule';
import Artists from './Artists';
import { useViewport } from './hooks';
import theme from './theme';
import useSiteContext from './SiteContext';

const LineupPage = ({ title, schedules }) => {
  const { viewport } = useSiteContext();
  const scheduleHeight = viewport.height - theme.sizes.header - 40;
  return (
    <div className="page-wrapper">
      {schedules.map(schedule => (
        <Schedule
          key={schedule._key}
          {...schedule}
          scheduleHeight={scheduleHeight}
        />
      ))}
      <Artists />
    </div>
  );
};

export default LineupPage;
