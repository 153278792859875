import React from 'react';
import { graphql } from 'gatsby';

import Wrapper from '../components/Wrapper';
import LineupPage from '../components/LineupPage';
const lineup = ({ data }) => {
  return (
    <Wrapper className="lineup page">
      <LineupPage {...data.sanityLineupPage} />
    </Wrapper>
  );
};
export const LineupQuery = graphql`
  query lineupQuery {
    sanityLineupPage {
      title
      schedules {
        _key
        heading
        backgroundImage {
          alt
          asset {
            assetId
            id
            sha1hash
            _id
            _rev
            _type
            fluid(maxWidth: 1600) {
              ...GatsbySanityImageFluid
            }
            metadata {
              lqip
              dimensions {
                width
                height
                aspectRatio
              }
            }
            url
          }
          crop {
            top
            right
            bottom
            left
          }
        }
        scheduleItems {
          _key
          eventType
          label
          subLabel
          time
        }
      }
    }
  }
`;
export default lineup;
