import React from 'react';
import styled from 'styled-components';

import Event from './Event';
import Image from './Image';
import { useViewport, useElementSize } from './hooks';
import { media } from './theme';

const Schedule = ({
  backgroundImage,
  heading,
  scheduleItems,
  scheduleHeight,
}) => {
  const viewport = useViewport();
  const [contentWrapperRef, contentWrapperSize, trigger] = useElementSize();

  return (
    <StyledSchedule
      className="schedule"
      ref={contentWrapperRef}
      scheduleHeight={scheduleHeight}
    >
      <Image
        image={backgroundImage}
        containerWidth={viewport.width}
        wrapperHeight={contentWrapperSize.height}
        className="schedule__bg-image"
        onLoad={trigger}
      />
      {heading && <h2 className="schedule__heading">{heading}</h2>}
      <ul className="schedule__list">
        {scheduleItems.map(event => (
          <Event key={event._key} {...event} />
        ))}
      </ul>
    </StyledSchedule>
  );
};

const StyledSchedule = styled.div`
  overflow: hidden;
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 6rem;
  ${media.break`
    height: ${({ scheduleHeight }) => scheduleHeight}px;
    padding-top: 5rem;
    padding-bottom: 5rem;
  `}
  .schedule {
    &__bg-image {
      position: absolute !important;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        ${media.break`
          width: 100%;
          height: auto;
          object-fit: none;
        `}
      }

      ::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: ${({ theme }) => theme.red};
        opacity: 20%;
      }
    }
    &__heading {
      position: relative;
      margin: 0;
      ${media.break`
        width: ${({ theme }) => theme.sizes.content}px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
      `}
    }
    &__list {
      list-style: none;
      position: relative;
      background: ${({ theme }) => theme.blue};
      padding: 1rem;
      ${media.break`
        width: ${({ theme }) => theme.sizes.content}px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
      `}
    }
  }
`;

export default Schedule;
