import React, { useState } from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { rgba } from 'polished';
import { FaSpotify } from 'react-icons/fa';

import MS from './MS';
import { media } from './theme';
import { useElementSize } from './hooks';
import { roundToNearest } from './utils';

const Artist = ({ name, local, spotify, image }) => {
  const [hover, setHover] = useState(false);
  const [transform, setTransform] = useState(0);
  const [elementRef, elementSize] = useElementSize();

  const { x, y, width, height } = elementSize;
  const centerX = x + width / 2;
  const centerY = y + height / 2;

  const farthestX = width / 2;
  const farthestY = height / 2;
  const farthest = Math.sqrt(Math.pow(farthestX, 2) + Math.pow(farthestY, 2));

  const handleMouseMove = e => {
    const { clientX, clientY } = e;
    const distanceX = centerX - clientX;
    const distanceY = centerY - clientY;
    const distance = Math.sqrt(Math.pow(distanceX, 2) + Math.pow(distanceY, 2));

    // console.log(1 - distance / farthest);
    const transform = roundToNearest(0.1 - distance / farthest / 10, 0.01);

    setTransform(1 + transform);
  };

  // console.log(transform);

  return (
    <StyledArtist
      className="artist"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      // onMouseMove={handleMouseMove}
      ref={elementRef}
    >
      <Image
        className="artist__image"
        fixed={image.asset.fixed}
        alt={image.alt}
        style={{
          transform: hover ? `scale(1.1)` : 'scale(1.0)',
        }}
      />
      <a
        className="artist__link"
        href={spotify}
        target="_blank"
        rel="noreferrer"
        // onHover={() => setHover(true)}
      >
        <h3 className="artist__name">{name}</h3>
        {local && (
          <span className="artist__local">
            <MS />
            Mississippi Artist
          </span>
        )}
        <span className="artist__spotify">
          <FaSpotify />
          Listen on Spotify
        </span>
      </a>
    </StyledArtist>
  );
};

const Image = styled(Img)`
  transition: 0.5s;
  transition-timing-function: ease;
`;

const StyledArtist = styled.li`
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-bottom: 2rem;
  overflow: hidden;
  ${media.break`
    margin-bottom: 5rem; 
  `}

  .artist {
    &__link {
      position: absolute;
      top: 0;
      left: 0;
      height: 300px;
      width: 300px;
      background: ${({ theme }) => rgba(theme.orange, 0.65)};
      text-decoration: none;
      color: white;
      padding: 5rem 5rem 2rem;
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
    }
    &__name {
      text-transform: uppercase;
      margin: 0;
      font-size: 3.6rem;
    }
    &__local {
      font-weight: ${({ theme }) => theme.font.bold};
      font-size: 2.2rem;
      text-transform: uppercase;
      display: flex;
      align-self: flex-start;
      line-height: 1.2;
      svg {
        flex: 70px;
      }
    }
    &__spotify {
      align-self: flex-end;

      font-size: 1.8rem;
    }
  }
  span {
    display: block;
  }
`;

export default Artist;
