import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import Artist from './Artist';
import { media } from './theme';

const Artists = () => {
  const {
    allSanityArtist: { edges: artists },
  } = useStaticQuery(graphql`
    {
      allSanityArtist {
        edges {
          node {
            id
            name
            local
            spotify
            image {
              alt
              asset {
                fixed(width: 300, height: 300) {
                  ...GatsbySanityImageFixed
                }
              }
            }
          }
        }
      }
    }
  `);
  // console.log(artists);
  return (
    <ArtistsWrapper className="artists">
      <h2 className="artists__heading">Listen Now</h2>
      <ul className="artists__list">
        {artists.map(({ node }) => (
          <Artist key={node.id} {...node} />
        ))}
      </ul>
    </ArtistsWrapper>
  );
};

const ArtistsWrapper = styled.section`
  padding: 1rem;
  .artists {
    &__heading {
      text-align: right;
      width: 300px;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      ${media.break`
        width: 950px; 
      `}
    }
    &__list {
      list-style: none;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      width: 950px;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
      @supports (display: grid) {
        /* grid-template-columns: repeat(2, 150px); */
        grid-auto-rows: 150px;
        grid-row-gap: 2rem;
        /* grid-gap: 1rem; */
        justify-content: space-around;
        justify-content: space-evenly;
        ${media.break`
          display: grid;
          justify-content: space-between;
          grid-template-columns: repeat(3, 300px);
          grid-auto-rows: 300px;
          grid-row-gap: 5rem;
        `}
        .artist {
          margin: 0;
        }
      }
    }
  }
`;

export default Artists;
