import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { media } from './theme';

const Event = ({ eventType, time, label, subLabel }) => {
  return (
    <StyledEvent
      className={classNames('schedule__item event', eventType)}
      type={eventType[0]}
    >
      {time && <time className="event__time">{time}</time>}
      <span className="event__label">{label}</span>
      {subLabel && <span className="event__sub-label">{subLabel}</span>}
    </StyledEvent>
  );
};

const StyledEvent = styled.li`
  color: ${({ type, theme }) => (type === 'artist' ? theme.orange : 'inherit')};
  font-size: ${({ type }) => (type === 'artist' ? '1.8rem' : '1.4rem')};
  margin: 1rem 0;
  font-family: ${({ theme }) => theme.font.heading};
  ${media.break`
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: ${({ type }) => (type === 'artist' ? '2.4rem' : '1.9rem')};
  `}
  .event {
    &__time {
      color: ${({ theme }) => theme.black};
      display: block;
      font-size: 1.8rem;
      ${media.break`
        font-size: ${({ type }) => (type === 'artist' ? '2.4rem' : '1.9rem')};
      `}
    }
    &__label {
      display: block;
      margin: 0.5rem 0;
      font-weight: ${({ type, theme }) =>
        type === 'artist' ? theme.font.bold : '300'};
      text-transform: ${({ type }) => (type === 'info' ? 'none' : 'uppercase')};
    }
    &__sub-label {
      display: block;
      font-size: 1.6rem;
      font-style: italic;
    }
  }
  @supports (display: grid) {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      'time label'
      ' .  sublabel';
    align-items: center;
    grid-column-gap: 1rem;
    ${media.break`
      grid-template-columns: 200px 1fr;
      grid-column-gap: 2rem;
      grid-row-gap: .5rem;
    `}
    .event {
      &__time {
        grid-area: time;
        text-align: right;
      }
      &__label {
        grid-area: label;
        margin: 0;
      }
      &__sub-label {
        grid-area: sublabel;
      }
    }
  }
`;

export default Event;
